import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Row, Col } from "reactstrap";
import { animated, useTrail } from "react-spring";
import { Waypoint } from "react-waypoint";

const PortfolioGrid = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      wpgraphql {
        cpt_properties(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            title
            slug
            acf_properties {
              address {
                city
                state
              }
              details {
                units
                status
              }
              thumbnail {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const properties = data.wpgraphql.cpt_properties.nodes;

  const [on, toggle] = useState(false);

  const trail = useTrail(properties.length, {
    from: { opacity: 0, transform: "translate(0px,100px)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate(0px,0px)" : "translate(0px,100px)"
    }
  });

  return (
    <section className="portfolio__grid mt-5">
      <Waypoint
        bottomOffset="12%"
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      {trail.map(({ ...animation }, index) => (
        <animated.div className="portfolio__item" style={{ ...animation }}>
          {properties[index].acf_properties.thumbnail != null && (
            <Link to={`portfolio/${properties[index].slug}`}>
              <div className="portfolio__thumbnail">
                <div
                  className="portfolio__thumbnail-image"
                  style={{
                    backgroundImage: `url(${properties[index].acf_properties.thumbnail.imageFile.childImageSharp.fluid.src})`
                  }}
                >
                  {properties[index].acf_properties.details.status != null && (
                    <p className="portfolio__sold">SOLD!</p>
                  )}
                  <h6 className="portfolio__thumbnail-overlay heading4 photoDetails">
                    <span>View Project</span>
                  </h6>
                </div>
              </div>
            </Link>
          )}

          <Row className="portfolio__item-details">
            <Col xs={{ size: 10 }} className="px-0">
              <h4 className="portfolio__item-name">
                <Link to={`portfolio/${properties[index].slug}`}>
                  {properties[index].title}
                </Link>
              </h4>{" "}
              <p className="body-copy">
                {" "}
                {properties[index].acf_properties.address.city},{" "}
                {properties[index].acf_properties.address.state}
              </p>
            </Col>
            <Col xs={{ size: 4 }} className="portfolio__unit-mix px-0">
              <h4 className="heading4">Units</h4>{" "}
              <p className="body-copy">
                {properties[index].acf_properties.details.units}
              </p>
            </Col>
          </Row>
        </animated.div>
      ))}
    </section>
  );
};

export default PortfolioGrid;
